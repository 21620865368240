:root {
    --amplify-primary-color: #007cba;
    --amplify-primary-tint: #006ba1;
    --amplify-primary-shade: #005a87;
}

body {
    margin: 0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul.highcharts-menu {
    margin-top: 10px !important;
}

.highcharts-container,
.highcharts-container svg {
    width: 100% !important;
}

button.page-number[class*="MuiButton-textSizeSmall"] {
    color: #4f575e;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: unset;
    text-transform: uppercase;
}


a[class*="MuiButton-textPrimary"][class*="MuiButton-textSizeSmall"] {
    width: 206px;
    height: 45px;
    background-color: #00AEEF;
    color: #fff;
}

[class*="MuiAutocomplete-inputRoot"][class*="MuiOutlinedInput-root"] [class*="MuiAutocomplete-input"] {
    padding: 0px 0px !important;
}

[class*="MuiSwitch-switchBase"][class*="Mui-checked.Mui-disabled"] {
    color: lightblue;
}
